/* FONTS */
/* body {
  font-family: 'Oswald', sans-serif;
  font-family: 'Fira Code', monospace;
  font-family: 'Roboto', sans-serif;
} */

body {
  --purple: #5240fe;
  --dark-gray: #22262b;
  --light-gray: #5b5b5b;
}

html,
body {
  overflow-x: hidden;
}

h2 {
  color: var(--purple);
  font-family: "Fira Code", monospace;
  text-decoration: underline;
  font-size: 60px;
}
