.sourceCodeLink {
  padding-top: 10px;
  display: flex;
  text-decoration: underline;
  color: var(--light-gray);
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  align-items: center;
}

.sourceCodeLink:hover {
  color: var(--purple);
  transform: scale(1.1);
}

.sourceCodeLink__github {
  padding-right: 5px;
}

.sourceCodeLink__externalLink {
  padding-left: 5px;
}
